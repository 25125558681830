// main.js
import Vue from 'vue'
import App from './App.vue'
import router from './router' // 导入路由配置
import infiniteScroll from 'vue-infinite-scroll'
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import './assets/css/main.css'; // 引入全局的CSS文件
import 'vant/lib/index.css';

import {Sku,Image,Search,Sticky, Stepper,GoodsActionIcon, GoodsActionButton,GoodsAction,Form, Empty, Tag, Divider, Button, Tabbar, TabbarItem, NavBar, Toast,CouponList,CouponCell,Circle, Swipe, SwipeItem, Lazyload, Tab, Tabs, RadioGroup, Radio, Cell, CellGroup, Icon, Dialog, Uploader, Field, Calendar, SubmitBar, Popup, Picker, Checkbox, CheckboxGroup, Overlay, DropdownMenu, DropdownItem, Progress, Collapse, CollapseItem, Step, Steps, PullRefresh ,NoticeBar} from 'vant';
let __vant = [Sku,Image,Search,Sticky, Stepper,GoodsActionIcon, GoodsActionButton,GoodsAction,Form, Empty, Tag, Divider, Button, Tabbar, TabbarItem, NavBar, Toast,CouponList,CouponCell,Circle, Swipe, SwipeItem, Lazyload, Tab, Tabs, RadioGroup, Radio, Cell, CellGroup, Icon, Dialog, Uploader, Field, Calendar, SubmitBar, Popup, Picker, Checkbox, CheckboxGroup, Overlay, DropdownMenu, DropdownItem, Progress, Collapse, CollapseItem, Step, Steps, PullRefresh,NoticeBar]
for (let i = 0; i < __vant.length; i++) {
    Vue.use(__vant[i]);
}

Vue.use(infiniteScroll)
Vue.use(ElementUI);

Vue.config.productionTip = false


new Vue({
	render: h => h(App),
	router, // 将router关联到Vue实例中
}).$mount('#app')