<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		created() {
			if (!localStorage.getItem('loadedBefore')) {
				localStorage.setItem('loadedBefore', true);
			} else {
			}
		}
	}
</script>
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: #ffffff;
		overflow-y: auto;
	}

	.custom-backtop {
		width: 45px;
		height: 45px;
	}

	.up {
		height: 100%;
		width: 100%;
		font-size: 28px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #F67F21;
	}

	/* 隐藏默认滚动条 */
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
</style>